import React, { useEffect, useState } from "react";

const About = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      name="about"
      className="w-full h-screen bg-[#0a192f] text-gray-300 md:-mb-[130px]"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        {!isMobile && (
          <p className="text-4xl font-bold inline border-b-4 border-teal-400">
            About
          </p>
        )}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4 md:justify-center">
            {isMobile && (
              <p className="text-4xl font-bold inline border-b-4 border-teal-400">
                About
              </p>
            )}
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>Hi, I'm Sasa, nice to meet you. Please take a look around.</p>
          </div>
          <div>
            <p>
              I am passionate about building excellent software that improves
              the lives of those around me. I specialize in creating software
              for clients ranging from individual and small-businesses all the
              way to large enterprise corporations. What would you do if you had
              a software expert available at your fingertips.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
